<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="col-md-6">
                                <v-text-field
                                    v-model="link"
                                    label="Link"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    v-model="description"
                                    label="Mô tả"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-datetime-picker dateFormat="dd-MM-yyyy" label="Thời gian bắt đầu" v-model="start_time"> </v-datetime-picker>
                            </div>

                            <!-- <div class="col-md-6">
                                <v-autocomplete
                                    v-model="item_id"
                                    :items="products"
                                    label="Sản phẩm"
                                    item-text="Name"
                                    item-value="Id"
                                    :multiple="true"
                                    chips
                                    deletable-chips
                                ></v-autocomplete>
                            </div> -->

                            <div class="col-md-6">
                                <v-select
                                    v-model="status"
                                    :items="status_data"
                                    label="Trạng thái"
                                    item-text="text"
                                    item-value="id"
                                ></v-select>
                            </div>

                            <div class="col-md-6">
                                <!-- <v-text-field
                                    type="number"
                                    v-model="push_type"
                                    label="Loại notify"
                                ></v-text-field> -->
                                <v-select
                                    v-model="push_type"
                                    :items="notify_data"
                                    label="Loại notify"
                                    item-text="text"
                                    item-value="id"
                                ></v-select>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    v-model="push_deeplink"
                                    label="Link push"
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-select
                                    v-model="video_size"
                                    :items="video_size_options"
                                    label="Video Size"
                                    item-text="text"
                                    item-value="id"
                                ></v-select>
                            </div>

                            <!-- <input type="file" ref="upload_detail" @change="upload_server_detail" style="display:none;">

                            <div class="col-md-12" v-if="activeTab">
                                <ul class="nav nav-tabs">
                                    <li v-for="tab of tabs" class="nav-item" :key="tab.Id">
                                        <a v-bind:class="{'nav-link d-flex align-items-center tab': true, 'active': (tab.Id == activeTab.Id) }">
                                            <span  v-on:click="activateTab(tab)" class="mr-5">Code: {{tab.Code}}</span>
                                                <i v-on:click="deleteTab(tab)" class="fa fa-times tab-icon" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link d-flex align-items-center tab add-btn">
                                            <i v-on:click="addNewTab()" class="fa fa-plus tab-icon" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                </ul>
                                <div v-if="activeTab.Id" class="card tab-contents">
                                    <div class="card-block">
                                        <div class="card-text">
                                            <div class="col-md-6">
                                                <div>
                                                    <v-text-field
                                                        v-model="activeTab.Code"
                                                        label="Code"
                                                        required
                                                    ></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div>
                                                    <v-text-field
                                                        v-model="activeTab.Name"
                                                        label="Tên sản phẩm"
                                                        required
                                                    ></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div>
                                                    <v-text-field
                                                        v-model="activeTab.Cost"
                                                        label="Giá"
                                                        required
                                                    ></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <v-text-field
                                                    v-model="activeTab.Url"
                                                    label="Hình ảnh (Sử dụng ảnh vuông)"
                                                    required
                                                    :append-icon="'mdi-paperclip'"
                                                    @click:append="browse_file_detail"
                                                ></v-text-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-md-6">
                                <v-btn class="ma-2 float-right" :loading="loading" color="info" @click="create_live">Thêm</v-btn>
                                <v-btn class="ma-2 float-right" :loading="loading" @click="cancel">Huỷ</v-btn>
                            </div>
                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

// for tabs
$color-transition-duration: 0.8s;
$accent-color: #2980b9;
$x-hover-color: #c0392b;
$smaller-nav-item-padding: 8px;
$icon-size: 0.875rem;

ul.nav-tabs {
    margin-top: 12px;
}

.card.tab-contents {
    border-top: none;
    border-radius: 0;
} 

.nav-link.tab {
    border-radius: 0;
    
    //Override the 16px Bootstrap default to give it a more tab-like feel
    padding-right: $smaller-nav-item-padding;
    
    span {
        transition: color $color-transition-duration;    
        color: black;
        opacity: 0.54;
        &:hover {
            color: $accent-color;
        }
    }
    
    &.active {
        span {
            opacity: 1;
        }
    }
           
    .icon-btn {
        margin-left: 6px;
        text-decoration: none;    
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        font-size: $icon-size;

            .fa {
                opacity: 0.54;
                transition: color $color-transition-duration;

                &:hover {
                    color: $x-hover-color;
                }
            }   
    }
    
    &.add-btn {
        padding-left: $smaller-nav-item-padding;        
        
        .icon-btn {
            color: $accent-color;
            margin: 0;    
        }
    }
}

.tab-icon:hover {
    color: #777;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    metaInfo: {
        title: 'Livestream'
    },
    data() {
        return {
            valid: true,
            link: "",
            description: "",
            item_id: [],
            start_time: new Date(),
            status: 0,
            push_type: 0,
            push_deeplink: "",
            video_size: "720x720",
            loading: false,
            name_rules: [],
            loading_search_product: false,

            // for tabs
            activeTab: null,
            tabs: [],
            status_data : [{
                id: 0,
                text: "Sắp tới"
            },
            {
                id: 1,
                text: "Đang live"
            },
            {
                id: 2,
                text: "Kết thúc"
            }],
            notify_data: [{
                id: 0,
                text: 'Notify Open App'
            },
            {
                id: 1,
                text: 'Notify Open Facebook'
            },
            {
                id: 2,
                text: 'Notify Open Youtube'
            }],
            video_size_options: [{
                id: '1280x720',
                text: '1280x720'
            },
            {
                id: '720x720',
                text: '720x720'
            }]
        };
    },
    watch: {
        
    },
    computed: {
        products() {
            return this.$store.getters['products/get_products'];
        },
    },
    components: {

    },
    methods: {
        create_live() {
            // var details = this.tabs.map(e => {
            //     delete e.Id;
            //     e.Cost = parseInt(e.Cost);
            //     return e;
            // });

            this.loading = true;
            var payload = {
                Fb_link_live: this.link,
                Description: this.description,
                // Item_id: this.item_id,
                Status: this.status ? parseInt(this.status) : 0,
                // Details: details,
                Push_type: this.push_type ? parseInt(this.push_type) : 0,
                Push_deeplink: this.push_deeplink,
                Video_size: this.video_size,
                Start_time: Math.floor(new Date(this.start_time).getTime() / 1000),
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('lives/create_live', payload).then(() => {
                this.loading = false;
                this.$router.push({ name: 'lives-index' });
            }).catch(() => {
                this.loading = false;
            });
        },
        cancel() {
            this.$router.push({ name: 'lives-index' });
        },
        
        search_products(val) {
            const q = val;
            this.$store.dispatch('products/get_all', {pageSize: 10, pageIndex: 1, categoryId: -1, nameQuery: q});
        },

        // for tabs
        activateTab: function(tab) {
            this.activeTab = tab;
        },
        addNewTab: function() {
            if(this.tabs.length == 0) {
                this.tabs.push({Id: 1, Code: '', Name: '', Cost: 0, Url: ""});
                this.activeTab = this.tabs[0];
            } else {
                let newId = this.tabs[this.tabs.length - 1].Id + 1;
                this.tabs.push({
                    Id: newId,
                    Code: '',
                    Name: '',
                    Cost: 0,
                    Url: "",
                });
                this.activateTab(this.tabs[this.tabs.length - 1]);
            }
        },
        deleteTab: function(tab) {
            if(this.tabs.length > 0) {
                this.tabs = this.tabs.filter(t => t.Id != tab.Id);
                if(this.tabs[this.tabs.length - 1]) {
                    this.activateTab(this.tabs[this.tabs.length - 1]);
                } else {
                    this.activateTab({});
                }
            }
        },
        browse_file_detail() {
            this.$refs.upload_detail.click();
        },
        upload_server_detail() {
            var self = this;
            this.$store.dispatch('uploads/upload', {file: this.$refs.upload_detail.files[0]}).then(res => {
                for(var i in self.tabs) {
                    if(self.tabs[i].Id == self.activeTab.Id) {
                        self.tabs[i].Url = res.data.url;
                    }
                }
            }).catch(e => {
                console.log(e);
            });
        },
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Livestream", route: "index" }, { title: "Thêm livestream" } ]);
        // this.$store.dispatch('products/get_all', {pageSize: 1000, pageIndex: 1, categoryId: -1});

        this.tabs.push({Id: 1, Code: '', Name: '', Cost: 0, Url: ""});
        this.activeTab = this.tabs[0];
    }
};
</script>



